import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

const App = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [query, setQuery] = useState("");
  const [pdf, setPdf] = useState(null);
  const [formData, setFormData] = useState({
    supplier: "",
    order_number: "",
    date: "",
    order_tipology: "",
    term_of_payment: "",
    currency: "",
    shipping_method: "",
    delivery_deadline: "",
    packaging: "",
    petitioner: "",
    contact_info: {
      tel: "",
      fax: "",
    },
    items: [],
    messages: "",
    important_notes: "",
    total_amount: "",
    company_info: {
      legal_headquarters: {
        P_IVA: "",
        Cod_Fisc: "",
        Cap_Soc_Euro: "",
      },
      operational_headquarters: [
        {
          address: "",
          postal_code: "",
          city: "",
          tel: "",
          fax: "",
        },
      ],
      azionista_unico: "",
      C_C_I_A_A_MILANO: "",
      I_i_v: "",
    },
  });
  const URL_LOCAL = "http://localhost:5432/";
  const URL_PROD = "https://takihyo-textile-admin.herokuapp.com/";
  const [loading, setLoading] = useState(false);

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  const handlePdfChange = (event) => {
    setPdf(event.target.files[0]);
  };

  const handleSubmitPDF = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    // formData.append("query", query);
    formData.append("pdf", pdf);

    try {
      const response = await fetch(`${URL_PROD}api/recipe-gpt`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      // console.log("Success:", data.message);
      setFormData(data.message);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  const [message, setMessage] = useState("");

  const handleChange = (e, group, key, index) => {
    const { id, value } = e.target;

    setFormData((prevState) => {
      if (group && key) {
        if (index !== undefined) {
          const updatedGroup = prevState[group].map((item, i) =>
            i === index ? { ...item, [key]: value } : item
          );
          return { ...prevState, [group]: updatedGroup };
        } else {
          return {
            ...prevState,
            [group]: { ...prevState[group], [key]: value },
          };
        }
      } else {
        return { ...prevState, [id]: value };
      }
    });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${URL_PROD}api/invoice-orders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: formData }),
      });

      if (response.ok) {
        const data = await response.json();
        // setMessage("Formulario enviado con éxito!");
        // console.log(formData, "formDataformDataformDataformData");
        // console.log("Success:", data);
        handleSubmitSheet();
      } else {
        // setMessage("Hubo un error al enviar el formulario.");
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      // setMessage("Hubo un error al enviar el formulario.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSheet = async (e) => {
    // e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(`${URL_PROD}api/send-invoice-to-sheet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        // setMessage("Formulario enviado con éxito!");
        // console.log(formData, "formDataformDataformDataformData");
        // console.log("Success:", data);
      } else {
        // setMessage("Hubo un error al enviar el formulario.");
        // console.error("Error:", response.statusText);
      }
    } catch (error) {
      // setMessage("Hubo un error al enviar el formulario.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
  
    }
  };
  const textEmails = `<shusuke.higashiyama@takihyo.co.jp>
To: Marina Inagaki <marina.inagaki@takihyo.co.jp>
Cc: Kosuke Murase <kosuke.murase@takihyo.co.jp>, Ena Otsuka <ena.otsuka@takihyo.co.jp>, Takato Aoyama
<takato.aoyama@takihyo.co.jp>, 625DB <625DB@takihyo.jp>, Masahiro Hanazawa
<masahiro.hanazawa@takihyo.co.jp>`;

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${URL_PROD}api/po-order-mail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        // setMessage("Formulario enviado con éxito!");
        // console.log(formData, "formDataformDataformDataformData");
        // console.log("Success:", data);
      } else {
        // setMessage("Hubo un error al enviar el formulario.");
        // console.error("Error:", response.statusText);
      }
    } catch (error) {
      // setMessage("Hubo un error al enviar el formulario.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
      closeModal()
    }
  };

  return (
    <div>
      {loading && <div className="loader"></div>}
      {/* <button onClick={handleSendEmail}>SEND EMAIL</button> */}
      <form className="form-container" onSubmit={handleSubmitPDF}>
        {/* <div className="form-group">
          <label htmlFor="query">Consulta:</label>
          <input
            type="text"
            id="query"
            value={query}
            onChange={handleQueryChange}
            required
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="pdf">PDF File:</label>
          <input
            type="file"
            id="pdf"
            accept="application/pdf"
            onChange={handlePdfChange}
            required
          />
          <div className="button-container">
            <button type="submit">Read pdf</button>
          </div>
        </div>
      </form>
      {formData?.items?.length === 0 ? (
        ""
      ) : (
        <>
          <div
            className="button-container"
            style={{ marginBottom: "1em", display: "flex", marginLeft: "2em" }}
          >
            <button
              type="submit"
              onClick={handleSubmit}
              style={{
                height: "40px",
                background: "black",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Save DB
            </button>

            <button
              style={{
                marginLeft: "2em",
                height: "40px",
                background: "#737373",
                border: "none",
                color: "white",
                cursor: "pointer",
              }}
              onClick={openModal}
            >
              Prepare Email
            </button>
          </div>

          <div>
            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              {/* <label>
                Recipient email <br />
                <input
                  style={{
                    height: "30px",
                    padding: "0 10px",
                    borderRadius: "5px",
                    border: "1px solid black",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  type="text"
                  placeholder="Email"
                />
              </label> */}
              <p style={{ width: "400px", fontSize: "13px" }}>
                <b>Shusuke Higashiyama</b> {textEmails}
              </p>
              <form>
                <p
                  style={{
                    marginBottom: "1em",
                    marginTop: "2em",
                    fontSize: "13px",
                  }}
                >
                  稲垣さん <br />
                  <br />
                  下記、年間プランアソート進行お願いします。
                  <br />
                  ＊YarnもしくはGreigeでのT/C管理可能の生機の場合、管理リストに追加お願いします
                </p>
                <p style={{ fontSize: "13px" }}>
                  <b>PO#2409 (*as 9/July portion of SS25 annual plan)</b>
                </p>
                {formData?.items.map((item, index) => (
                  <ul style={{ listStyle: "none" }}>
                    <li style={{ fontSize: "13px" }}>
                      {item?.model} / {item.code}
                    </li>
                  </ul>
                ))}
                <br />
                <p style={{ fontSize: "13px" }}>
                  <b>(1)price</b>
                </p>
                JPY 825/mtr CIF MILANO BY AIR (*air surcharge INCLUDED)
                <br /> <br />
                <p style={{ fontSize: "13px" }}>
                  <b>(2)assort</b>
                </p>
                <div style={{ fontSize: "13px" }}>
                  c/#00300 --- 450m <br />
                  c/#31130 --- 650m <br />
                  c/#35580 --- 200m <br />
                  c/#62140 --- 1200m <br />
                  <br />
                </div>
                <p style={{ fontSize: "13px" }}>
                  <b>(3)delivery date</b>
                </p>
                <ul style={{ listStyle: "none" }}>
                  <li style={{ fontSize: "13px" }}>
                    {formData?.items[0].delivery_date}
                  </li>
                </ul>
              </form>

              <div
                className="button-container"
                style={{
                  marginBottom: "1em",
                  marginTop: "2em",
                  display: "flex",
                }}
              >
                <button
                  type="submit"
                  onClick={closeModal}
                  style={{
                    height: "40px",
                    background: "black",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  onClick={handleSendEmail}
                  style={{
                    marginLeft: "2em",
                    height: "40px",
                    background: "black",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Confirm and send
                </button>
              </div>
            </Modal>
          </div>

          <form className="form-container">
            <div className="form-group">
              <label>
                Supplier:
                <input
                  type="text"
                  id="supplier" // Es importante que el ID coincida con la clave en formData
                  value={formData?.supplier}
                  onChange={handleChange} // No necesitas pasar group ni key
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Order Number:
                <input
                  type="text"
                  value={formData?.order_number}
                  onChange={(e) => handleChange(e, "order_number")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Date:
                <input
                  type="date"
                  value={formData?.date}
                  onChange={(e) => handleChange(e, "date")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Order Tipology:
                <input
                  type="text"
                  value={formData?.order_tipology}
                  onChange={(e) => handleChange(e, "order_tipology")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Term of Payment:
                <input
                  type="text"
                  value={formData?.term_of_payment}
                  onChange={(e) => handleChange(e, "term_of_payment")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Currency:
                <input
                  type="text"
                  value={formData?.currency}
                  onChange={(e) => handleChange(e, "currency")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Shipping Method:
                <input
                  type="text"
                  value={formData?.shipping_method}
                  onChange={(e) => handleChange(e, "shipping_method")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Delivery Deadline:
                <input
                  type="date"
                  value={formData?.delivery_deadline}
                  onChange={(e) => handleChange(e, "delivery_deadline")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Packaging:
                <input
                  type="text"
                  value={formData?.packaging}
                  onChange={(e) => handleChange(e, "packaging")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Petitioner:
                <input
                  type="text"
                  value={formData?.petitioner}
                  onChange={(e) => handleChange(e, "petitioner")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Contact Info - Tel:
                <input
                  type="tel"
                  value={formData?.contact_info.tel}
                  onChange={(e) => handleChange(e, "contact_info", "tel")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Contact Info - Fax:
                <input
                  type="text"
                  value={formData?.contact_info.fax}
                  onChange={(e) => handleChange(e, "contact_info", "fax")}
                />
              </label>
            </div>

            <h3>Items</h3>
            {formData?.items.map((item, index) => (
              <div className="form-group nested-form-group" key={index}>
                <label>
                  Line:
                  <input
                    type="text"
                    value={item?.line}
                    onChange={(e) => handleChange(e, "items", "line", index)}
                  />
                </label>
                <label>
                  Model:
                  <input
                    type="text"
                    value={item?.model}
                    onChange={(e) => handleChange(e, "items", "model", index)}
                  />
                </label>
                <label>
                  Code:
                  <input
                    type="text"
                    value={item.code}
                    onChange={(e) => handleChange(e, "items", "code", index)}
                  />
                </label>
                <label>
                  Colour:
                  <input
                    type="text"
                    value={item?.colour}
                    onChange={(e) => handleChange(e, "items", "colour", index)}
                  />
                </label>
                <label>
                  Measure:
                  <input
                    type="text"
                    value={item.measure}
                    onChange={(e) => handleChange(e, "items", "measure", index)}
                  />
                </label>
                <label>
                  Label:
                  <input
                    type="text"
                    value={item?.label}
                    onChange={(e) => handleChange(e, "items", "label", index)}
                  />
                </label>
                <label>
                  Supplier Code:
                  <input
                    type="text"
                    value={item?.supplier_code}
                    onChange={(e) =>
                      handleChange(e, "items", "supplier_code", index)
                    }
                  />
                </label>
                <label>
                  Quantity:
                  <input
                    type="number"
                    value={item?.quantity}
                    onChange={(e) =>
                      handleChange(e, "items", "quantity", index)
                    }
                  />
                </label>
                <label>
                  Price:
                  <input
                    type="number"
                    value={item?.price}
                    onChange={(e) => handleChange(e, "items", "price", index)}
                  />
                </label>
                <label>
                  Amount:
                  <input
                    type="number"
                    value={item?.amount}
                    onChange={(e) => handleChange(e, "items", "amount", index)}
                  />
                </label>
                <label>
                  Delivery Date:
                  <input
                    type="date"
                    value={item?.delivery_date}
                    onChange={(e) =>
                      handleChange(e, "items", "delivery_date", index)
                    }
                  />
                </label>
              </div>
            ))}

            <h3>Company Info</h3>
            <div className="form-group">
              <label>
                Legal Headquarters - P_IVA:
                <input
                  type="text"
                  value={formData?.company_info.legal_headquarters.P_IVA}
                  onChange={(e) => handleChange(e, "company_info", "P_IVA")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Legal Headquarters - Cod_Fisc:
                <input
                  type="text"
                  value={formData?.company_info.legal_headquarters.Cod_Fisc}
                  onChange={(e) => handleChange(e, "company_info", "Cod_Fisc")}
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                Legal Headquarters - Cap_Soc_Euro:
                <input
                  type="text"
                  value={formData?.company_info.legal_headquarters.Cap_Soc_Euro}
                  onChange={(e) =>
                    handleChange(e, "company_info", "Cap_Soc_Euro")
                  }
                />
              </label>
            </div>

            <h4>Operational Headquarters</h4>
            {formData?.company_info?.operational_headquarters.map(
              (hq, index) => (
                <div className="form-group nested-form-group" key={index}>
                  <label>
                    Address:
                    <input
                      type="text"
                      value={hq.address}
                      onChange={(e) =>
                        handleChange(e, "company_info", "address", index)
                      }
                    />
                  </label>
                  <label>
                    Postal Code:
                    <input
                      type="text"
                      value={hq.postal_code}
                      onChange={(e) =>
                        handleChange(e, "company_info", "postal_code", index)
                      }
                    />
                  </label>
                  <label>
                    City:
                    <input
                      type="text"
                      value={hq.city}
                      onChange={(e) =>
                        handleChange(e, "company_info", "city", index)
                      }
                    />
                  </label>
                  <label>
                    Tel:
                    <input
                      type="tel"
                      value={hq.tel}
                      onChange={(e) =>
                        handleChange(e, "company_info", "tel", index)
                      }
                    />
                  </label>
                  <label>
                    Fax:
                    <input
                      type="text"
                      value={hq.fax}
                      onChange={(e) =>
                        handleChange(e, "company_info", "fax", index)
                      }
                    />
                  </label>
                </div>
              )
            )}
            <div className="form-group">
              <label>
                Azionista Unico:
                <input
                  type="text"
                  value={formData?.company_info?.azionista_unico}
                  onChange={(e) =>
                    handleChange(e, "company_info", "azionista_unico")
                  }
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                C_C_I_A_A_MILANO:
                <input
                  type="text"
                  value={formData?.company_info.C_C_I_A_A_MILANO}
                  onChange={(e) =>
                    handleChange(e, "company_info", "C_C_I_A_A_MILANO")
                  }
                />
              </label>
            </div>
            <div className="form-group">
              <label>
                I_i_v:
                <input
                  type="text"
                  value={formData?.company_info.I_i_v}
                  onChange={(e) => handleChange(e, "company_info", "I_i_v")}
                />
              </label>
            </div>
          </form>
        </>
      )}

      {/* <button onClick={handleSubmitSheet}>button to sheet</button> */}
    </div>
  );
};

export default App;
